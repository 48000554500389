import { Link } from 'react-router-dom'
import { Button, Label, Popup, SemanticCOLORS } from 'semantic-ui-react'
import { EditableInformationFieldConfig } from '../../../services/EditableRecordInformationService'
import { CustomerSelectorNew, SearchableSelector } from '../../../views/formComponents/Selectors'
import { mapStringsToOptions } from '../../../views/Utils'
import { Customer } from '../../../actions/NewCustomers'
import {
  IPackageWithTenantCount,
  IPackageVersionWithTenantCount,
  IPackageOwnerType,
  IPackageProperties,
  IPackageStatus,
  IPackageType
} from '../../../actions/Package'
import { formatDate } from '../../../utils/dateUtils'

export const statusColors: Record<string, SemanticCOLORS> = {
  ALPHA: 'purple',
  BETA: 'orange',
  GA: 'green',
  DEPRECATED: 'grey'
}

export const typeColors: Record<string, SemanticCOLORS> = {
  CUSTOM: 'brown',
  EXTENSIONS: 'pink',
  PRODUCT: 'teal',
  TEST: 'red'
}

const statusOptions = mapStringsToOptions([
  IPackageStatus.ALPHA,
  IPackageStatus.BETA,
  IPackageStatus.GA,
  IPackageStatus.DEPRECATED
])
const typeOptions = mapStringsToOptions([
  IPackageType.CUSTOM,
  IPackageType.EXTENSION,
  IPackageType.PRODUCT,
  IPackageType.TEST
])

export interface IField {
  title: string
  viewComponent: JSX.Element
  editComponent: JSX.Element
}

const getDateComponent = (value: string) => <>{formatDate(value, 'MMM DD, YYYY - hh:mma')}</>

export const getOwnerTypeComponent = (value: string) => (
  <Label basic color={value === IPackageOwnerType.SKEDULO ? 'blue' : 'grey'}>
    {value}
  </Label>
)

export const getStatusComponent = (value: string) => (
  <Label color={statusColors[value] ?? 'grey'} basic>
    {value}
  </Label>
)

export const getTypeComponent = (value: string) => (
  <Label color={typeColors[value] ?? 'grey'} basic>
    {value}
  </Label>
)

export const getPackageDetailsFields = (): EditableInformationFieldConfig<IPackageWithTenantCount>[] => [
  {
    headerTitle: 'Name',
    fieldName: 'packageName',
    isString: true,
    editable: false
  },
  {
    headerTitle: 'Total teams',
    fieldName: 'count',
    isString: true,
    editable: false
  },
  {
    headerTitle: 'Latest version',
    fieldName: 'latestVersion',
    isString: true,
    editable: false
  },
  {
    headerTitle: 'Latest version description',
    fieldName: 'latestVersionDescription',
    isString: true,
    editable: false
  },
  {
    headerTitle: 'Created',
    fieldName: 'createdAt',
    isString: false,
    editable: false,
    DisplayComponent: ({ value }) => getDateComponent(value)
  },
  {
    headerTitle: 'Created by',
    fieldName: 'createdBy',
    isString: true,
    editable: false
  },
  {
    headerTitle: 'Latest created',
    fieldName: 'latestCreatedAt',
    isString: false,
    editable: false,
    DisplayComponent: ({ value }) => getDateComponent(value)
  },
  {
    headerTitle: 'Latest created by',
    fieldName: 'latestCreatedBy',
    isString: true,
    editable: false
  },
  {
    headerTitle: 'Owner type',
    fieldName: 'ownerType',
    isString: false,
    editable: false,
    DisplayComponent: ({ value }) => getOwnerTypeComponent(value)
  }
]

export const getPackagePropertiesFields = (
  customer: Customer | undefined
): EditableInformationFieldConfig<IPackageProperties>[] => [
  {
    headerTitle: 'Type',
    DisplayComponent: ({ value }) => getTypeComponent(value),
    EditComponent: ({ editedValue, editFn }) => (
      <SearchableSelector
        value={editedValue as string}
        onChange={(_, { value }) => editFn(value as string)}
        options={typeOptions}
      />
    ),
    fieldName: 'type',
    isString: false,
    editable: true
  },
  {
    headerTitle: 'Status',
    DisplayComponent: ({ value }) => getStatusComponent(value),
    EditComponent: ({ editedValue, editFn }) => (
      <SearchableSelector
        value={editedValue as string}
        onChange={(_, { value }) => editFn(value as string)}
        options={statusOptions}
      />
    ),
    fieldName: 'status',
    isString: false,
    editable: true
  },
  {
    headerTitle: 'Customer',
    EditComponent: ({ editedValue, editFn }) => (
      <CustomerSelectorNew value={editedValue as string} editFn={editFn} allowEmpty />
    ),
    DisplayComponent: ({ value }) => (
      <Link to={`/customers/${value}`}>
        <p> {customer?.name} </p>
      </Link>
    ),
    fieldName: 'customerId',
    isString: false,
    editable: true
  },
  {
    headerTitle: 'Custom',
    fieldName: 'custom',
    isString: true,
    editable: true
  },
  {
    headerTitle: 'Last modified',
    DisplayComponent: ({ value }) => getDateComponent(value),
    fieldName: 'lastModifiedAt',
    isString: false,
    editable: false
  },
  {
    headerTitle: 'Last modified by',
    fieldName: 'lastModifiedBy',
    isString: true,
    editable: false
  }
]

export const getPackageVersionDetailsFields = (
  toggleModalIsOpen: () => void
): EditableInformationFieldConfig<IPackageVersionWithTenantCount>[] => [
  {
    headerTitle: 'Name',
    fieldName: 'packageName',
    isString: true,
    editable: false
  },
  {
    headerTitle: 'Version',
    fieldName: 'version',
    isString: true,
    editable: false
  },
  {
    headerTitle: 'Total teams',
    fieldName: 'count',
    isString: true,
    editable: false
  },
  {
    headerTitle: 'Description',
    fieldName: 'description',
    isString: true,
    editable: false
  },
  {
    headerTitle: 'Source URL',
    fieldName: 'sourceUrl',
    isString: false,
    editable: false,
    DisplayComponent: ({ value }) => (
      <Link to={value} target="_blank" className="table-cell-overflow">
        {value}
      </Link>
    )
  },
  {
    headerTitle: 'Artifacts',
    fieldName: 'artifacts',
    isString: false,
    editable: false,
    DisplayComponent: ({ value }) => (
      <Popup
        trigger={
          <div>
            <Button disabled={!value || value.length === 0} onClick={toggleModalIsOpen} content="Inspect artifacts" />
          </div>
        }
        content="Artifact manifest not available for this version. Register a new version to generate a manifest."
        disabled={value?.length > 0}
      />
    )
  },
  {
    headerTitle: 'Created',
    fieldName: 'createdAt',
    isString: false,
    editable: false,
    DisplayComponent: ({ value }) => getDateComponent(value)
  },
  {
    headerTitle: 'Created by',
    fieldName: 'createdBy',
    isString: true,
    editable: false
  }
]
