import { useParams } from 'react-router'
import { Grid, Header, Icon } from 'semantic-ui-react'
import { IPackageProperties, IPackageWithTenantCount } from '../../actions/Package'
import { EditableInformation } from '../../components/InformationComponents/EditableInformation'
import { useGetRegionQuery } from '../../queries/GetRegionsQuery'
import { useGetPackageQuery } from '../../queries/packages/GetPackageQuery'
import { DisplayOnlyInformation } from '../../components/InformationComponents/DisplayOnlyInformation'
import {
  usePackageInformationService,
  usePackagePropertiesInformationService
} from './services/PackageInformationService'
import { PackageVersionsList } from './component/PackageVersionsList'

export const PackageDetails = () => {
  const params = useParams()
  const packageName = params.id!

  const { data: packageData } = useGetPackageQuery(packageName)
  const { data: regionsInfo } = useGetRegionQuery()

  const packagePropertiesInformationService = usePackagePropertiesInformationService(packageData!)
  const packageInformationService = usePackageInformationService(packageData!, regionsInfo || [])

  return (
    <div className="package-component">
      <Grid stackable>
        <Grid.Column floated="left" width={5} textAlign="left">
          <a href="/packages">
            <Icon name="chevron left" size="small" /> Packages
          </a>
          <Header as="h2">{packageName}</Header>
        </Grid.Column>
      </Grid>
      <Grid stackable style={{ height: 'calc(100% - 50px - 3rem)' }}>
        <Grid.Column width={5} style={{ height: '100%' }} className="scrollable">
          <DisplayOnlyInformation<IPackageWithTenantCount> {...packageInformationService} />
          <br />
          <EditableInformation<IPackageProperties> {...packagePropertiesInformationService} useAlternateGridUI />
        </Grid.Column>
        <Grid.Column width={11} style={{ height: '100%' }} className="scrollable">
          <PackageVersionsList
            title="Package versions"
            packageName={packageName}
            regionsInfo={regionsInfo || []}
            latestVersion={packageData?.latestVersion}
          />
        </Grid.Column>
      </Grid>
    </div>
  )
}
