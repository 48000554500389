import { useGetPackageTenantCountQuery } from '../../../queries/packages/GetPackageTenantCountQuery'
import { IRegionInfo } from '../../../actions/Regions'
import { useGetCustomerQuery } from '../../../queries/GetCustomerQuery'
import { createErrorToast } from '../../../views/alertComponents/Alert'
import { IPackage, IPackageProperties, IPackageWithTenantCount } from '../../../actions/Package'
import {
  useDisplayOnlyRecordInformationService,
  useEditableRecordInformationService
} from '../../../services/EditableRecordInformationService'
import { useGetPackagePropertiesQuery } from '../../../queries/packages/GetPackageQuery'
import { useUpdatePackagePropertiesMutation } from '../../../mutations/packages/UpdatePackagePropertiesMutation'
import { getPackageDetailsFields, getPackagePropertiesFields } from './PackageInformationFields'
import { generatePackageTenantQuery, combinePackageWithTenantCount } from './PackageTenantCountUtils'

export const usePackageInformationService = (packageData: IPackage, regionsInfo: IRegionInfo[]) => {
  const { data: packageTenantCount, isLoading: isLoadingPackageTenantCount } = useGetPackageTenantCountQuery(
    regionsInfo,
    generatePackageTenantQuery(packageData)
  )
  const packageWithTenantCount = combinePackageWithTenantCount(packageData, packageTenantCount || [])

  const packageInformation = useDisplayOnlyRecordInformationService<IPackageWithTenantCount>(
    'Package details',
    packageWithTenantCount!,
    getPackageDetailsFields(),
    isLoadingPackageTenantCount
  )

  return packageInformation
}

export const usePackagePropertiesInformationService = (packageData: IPackage) => {
  const getPackageQuery = useGetPackagePropertiesQuery(packageData?.packageName)
  const updatePackageMutation = useUpdatePackagePropertiesMutation(packageData?.packageName)

  const { data: customer } = useGetCustomerQuery(packageData?.properties?.customerId ?? '')

  const packagePropertiesInformation = useEditableRecordInformationService<IPackageProperties>(
    'Package properties',
    getPackageQuery,
    updatePackageMutation,
    getPackagePropertiesFields(customer)
  )

  const updateProperties = (editedData: Partial<IPackageProperties>) => {
    if (editedData) {
      let newEditedData = editedData

      if (newEditedData.custom) {
        try {
          newEditedData = { ...newEditedData, custom: JSON.parse(newEditedData.custom as unknown as string) }
        } catch (e) {
          createErrorToast('Error parsing JSON for custom property')
        }
      }

      if (newEditedData.customerId === '') {
        newEditedData = { ...newEditedData, customerId: null }
      }

      packagePropertiesInformation.saveEdits(newEditedData)
    }
  }

  return {
    ...packagePropertiesInformation,
    title: `${packagePropertiesInformation.recordName}`,
    saveEdits: updateProperties,
    submitting: packagePropertiesInformation.submitting
  }
}
