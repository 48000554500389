import { Grid, Pagination, Input } from 'semantic-ui-react'
import { Row } from 'react-table'
import { useState } from 'react'
import { useDebounce } from 'usehooks-ts'
import { IPackageWithTenantCount } from '../../actions/Package'
import { useGetRegionQuery } from '../../queries/GetRegionsQuery'
import { formatDate } from '../../utils/dateUtils'
import { handlePaginationChange } from '../../views/tableComponents/TableFunctions'
import { createMemoizedColumns, Table } from '../../components/TableComponents/ReactTable'
import { useGetPackageTenantCountQuery } from '../../queries/packages/GetPackageTenantCountQuery'
import { PackagesQueryResponse, useGetPackagesInfiniteQuery } from '../../queries/GetPackagesInfiniteQuery'
import { combinePackageArrays, generatePackageTenantQueries } from './services/PackageTenantCountUtils'
import { useInfiniteTableService } from './services/InfiniteTableService'
import { getOwnerTypeComponent, getStatusComponent, getTypeComponent } from './services/PackageInformationFields'

export const renderPackageNameURL = (packageName: string) => (
  <a href={`/packages/${encodeURIComponent(packageName)}`}>{packageName}</a>
)

export const renderLatestVersionURL = (packageName: string, packageVersion: string) => (
  <a href={`/packages/${encodeURIComponent(packageName)}/${encodeURIComponent(packageVersion)}`}>{packageVersion}</a>
)

const GlobalPackageList = () => {
  const { data: regionsInfo } = useGetRegionQuery()
  const [searchValue, setSearchValue] = useState<string>('')
  const [activePage, setActivePage] = useState(1)
  const debouncedSearchValue = useDebounce(searchValue, 500)
  const packagesInfiniteQueryResult = useGetPackagesInfiniteQuery(debouncedSearchValue)

  const {
    pageData,
    isLoading: isLoadingPackagesData,
    maxPages,
    handleSearchChange
  } = useInfiniteTableService<PackagesQueryResponse>({
    infiniteQueryResult: packagesInfiniteQueryResult,
    setSearchValue,
    activePage,
    setActivePage
  })

  const packages = pageData?.result
  const { data: packageTenantCounts, isLoading: isLoadingPackageTenantCount } = useGetPackageTenantCountQuery(
    regionsInfo || [],
    generatePackageTenantQueries(packages || [])
  )
  const packagesWithTenantCount = combinePackageArrays(packages || [], packageTenantCounts || [])

  const tableColumns = createMemoizedColumns<IPackageWithTenantCount>(
    [
      {
        Header: 'Name',
        accessor: 'packageName',
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => renderPackageNameURL(row.original.packageName)
      },
      {
        Header: 'Latest version',
        accessor: 'latestVersion',
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) =>
          renderLatestVersionURL(row.original.packageName, row.original.latestVersion)
      },
      {
        Header: 'Latest version description',
        accessor: 'latestVersionDescription'
      },
      {
        Header: 'Total teams',
        accessor: 'count'
      },
      {
        Header: 'Created',
        accessor: 'createdAt',
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => (
          <>{formatDate(row.original.createdAt, 'MMM DD, YYYY - hh:mma')}</>
        )
      },
      {
        Header: 'Created by',
        accessor: 'createdBy'
      },
      {
        Header: 'Latest created',
        accessor: 'latestCreatedAt',
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => (
          <>{formatDate(row.original.latestCreatedAt, 'MMM DD, YYYY - hh:mma')}</>
        )
      },
      {
        Header: 'Latest created by',
        accessor: 'latestCreatedBy'
      },
      {
        Header: 'Owner',
        accessor: 'ownerType',
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => getOwnerTypeComponent(row.original.ownerType)
      },
      {
        Header: 'Status',
        accessor: (pkg: IPackageWithTenantCount) => pkg.properties.status,
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => getStatusComponent(row.original.properties.status)
      },
      {
        Header: 'Type',
        accessor: (pkg: IPackageWithTenantCount) => pkg.properties.type,
        Cell: ({ row }: { row: Row<IPackageWithTenantCount> }) => getTypeComponent(row.original.properties.type)
      }
    ],
    []
  )

  return (
    <div className="package-component">
      <Grid>
        <Grid.Row>
          <Grid.Column width={9} verticalAlign="middle">
            <h3>Packages</h3>
          </Grid.Column>
          <Grid.Column width={7} textAlign="right">
            <Input
              placeholder="Search"
              onChange={handleSearchChange}
              icon="search"
              iconPosition="left"
              value={searchValue}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <Table
        columns={tableColumns}
        data={packagesWithTenantCount ?? []}
        emptyMessage="No packages found."
        textAlign="left"
        color="blue"
        loading={isLoadingPackagesData || isLoadingPackageTenantCount}
      />

      {maxPages > 1 && (
        <Pagination
          activePage={activePage}
          boundaryRange={0}
          siblingRange={4}
          onPageChange={handlePaginationChange(setActivePage)}
          totalPages={maxPages}
          ellipsisItem={null}
          firstItem={null}
          lastItem={null}
        />
      )}
    </div>
  )
}

export default GlobalPackageList
