import { Grid } from 'semantic-ui-react'
import { EditableInformationFieldData } from '../../services/EditableRecordInformationService'
import { EditableDisplayBaseField } from './EditableDisplayBaseField'
import { EditableDisplayStringField } from './EditableDisplayStringField'

export const renderEditableDisplay = <T extends object>(
  fields: EditableInformationFieldData<T>[],
  editing: boolean,
  isEditableTable?: boolean,
  useAlternateGridUI?: boolean
) =>
  fields.map(field => {
    const fieldValue = (value: unknown) => (value instanceof Object ? JSON.stringify(value) : (value as string))

    if (field.isString) {
      const displayValue = fieldValue(field.value)
      const editedValue = fieldValue(field.editedValue)
      return (
        <EditableDisplayStringField
          isEditableTable={isEditableTable ?? false}
          key={field.fieldName as string}
          fieldName={(field.headerTitle ?? field.fieldName) as string}
          editable={editing && field.editable}
          showField={field.showField}
          useAlternateGridUI={useAlternateGridUI}
          editFieldFn={field.editFieldFn}
          editedValue={editedValue}
          value={displayValue}
          error={field.error}
          DisplayComponent={field.DisplayComponent ? <field.DisplayComponent value={displayValue} /> : undefined}
        />
      )
    }

    return (
      <EditableDisplayBaseField
        isEditableTable={isEditableTable ?? false}
        key={field.fieldName as string}
        fieldName={(field.headerTitle ?? field.fieldName) as string}
        editable={editing && field.editable}
        showField={field.showField}
        useAlternateGridUI={useAlternateGridUI}
        DisplayComponent={<field.DisplayComponent value={field.value} />}
        EditComponent={
          field.editable ? <field.EditComponent editedValue={field.editedValue} editFn={field.editFieldFn} /> : <div />
        }
      />
    )
  })

export const InformationComponent = ({
  useAlternateGridUI,
  header,
  editButton,
  display
}: {
  useAlternateGridUI: boolean | undefined
  header: JSX.Element
  display: JSX.Element[]
  editButton?: JSX.Element
}): JSX.Element =>
  useAlternateGridUI ? (
    <Grid>
      <Grid.Row>
        <Grid.Column width={9} verticalAlign="middle">
          {header}
        </Grid.Column>
        <Grid.Column width={7} textAlign="right">
          {editButton}
        </Grid.Column>
      </Grid.Row>
      {display}
    </Grid>
  ) : (
    <>
      {display}
      {editButton}
    </>
  )
